import React from 'react'
import { Link } from 'gatsby'
import { CategoriaNeumatico } from '../../types/Neumatico'
import { CategoriaVehiculo } from '../../types/Vehiculo'
import withMarcas, { Marca } from '../withMarcas'
import route from '../../utils/route'
import styles from './listaMarcas.module.scss'

interface ListaMarcasInnerProps {
  marcas: Marca[]
  type: CategoriaNeumatico

  categoria: CategoriaVehiculo
}

const ListaMarcasInner: React.FC<ListaMarcasInnerProps> = ({
  marcas,
  type,
  categoria = 'coche',
}) => {
  if (!marcas.length) return null
  return (
    <React.Fragment>
      {marcas
        .filter((item) => {
          return item.clasificacion === type && item[categoria]
        })
        .map((item, index) => {
          let Logo
          try {
            Logo = require(`../../assets/images/logo_marcas/${item.logo.toLowerCase()}.svg`)
          } catch (e) {
            return false
          }

          return (
            <Link
              key={item.id}
              to={route('neumaticos.marca', {
                marca: item.label.toLowerCase(),
              })}>
              <Logo key={index} className={styles.logo} />
            </Link>
          )
        })}
    </React.Fragment>
  )
}
const ListaMarcas = withMarcas(ListaMarcasInner)
export default ListaMarcas
