import { graphql, useStaticQuery } from 'gatsby'
import React, { ComponentType, useMemo } from 'react'
import { CategoriaNeumatico } from '../types/Neumatico'

interface MarcaNode {
  id_site_marca_neumatico: number
  marca: string
  orden: number
  clasificacion: CategoriaNeumatico
  logo_marca: string
  coche: boolean
  moto: boolean
}

export interface Marca {
  id: number
  label: string
  value: number
  clasificacion: CategoriaNeumatico
  orden: number
  logo: string
  coche: boolean
  moto: boolean
}
export interface InjectedMarcas {
  marcas: Marca[]
}

const withMarcas = <P extends InjectedMarcas>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof InjectedMarcas>> => {
  return function MarcasHOC(props: Omit<P, keyof InjectedMarcas>) {
    const data = useStaticQuery(graphql`
      query MarcasQuery {
        allNeumaticosMarca(sort: { order: ASC, fields: orden }) {
          nodes {
            id_site_marca_neumatico
            marca
            orden
            clasificacion
            logo_marca
            coche
            moto
          }
        }
      }
    `)
    const marcas = useMemo(
      () =>
        data.allNeumaticosMarca.nodes.map((node: MarcaNode) => ({
          id: node.id_site_marca_neumatico,
          label: node.marca,
          value: node.id_site_marca_neumatico,
          clasificacion: node.clasificacion,
          orden: node.orden,
          logo: node.logo_marca,
          coche: node.coche,
          moto: node.moto,
        })),
      [data]
    )

    return <Component marcas={marcas} {...(props as P)} />
  }
}

export default withMarcas
