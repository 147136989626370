import React from 'react'
import styles from './index.module.scss'

interface Props {
  children?: JSX.Element | JSX.Element[]
  text: string
}
export default function Title({ children, text }: Props) {
  return (
    <div className={styles.title_container}>
      <h1 className={styles.title}>{text}</h1>
      {children ? (
        <div className={styles.childContainer}>{children}</div>
      ) : null}
    </div>
  )
}
