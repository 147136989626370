import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'
import route from '../../../src/common/utils/route'
import { getFullCompany } from '../../../src/common/utils/getCompany'
import { locale } from '../../../src/common/i18n'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingEconomy = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    // Escrito de esta forma para que no se transforme en data.banners_mobile
    // que da error al comprobar tipos.
    data[`${'banners'}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`${'banners'}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <div className={styles.container}>
      <Title text="Pneus Económicos" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
        Na Rodi Motor, trabalhamos com as marcas de pneus baratos com a melhor relação qualidade e preço que existem no mercado. Isto permite-nos oferecer os melhores preços com uma excelente qualidade de serviço. As nossas marcas de gama Económica são uma excelente escolha para os condutores que procuram um pneu barato que tenha o reconhecimento de profissionais da indústria de pneus.
        </p>

        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />

        <section className={styles.section}>
          <h2>Grande seleção de Pneus Económicos</h2>
          <p>
          Procura os melhores preços em pneus? Na nossa seleção de Marcas Economy, encontrará marcas de pneus baratos especialmente escolhidas pelos técnicos especialistas da Rodi Motor para oferecer aos seus clientes a melhor qualidade ao melhor preço. Estas marcas são novas na indústria e têm promoções em pneus com preços imbatíveis e materiais de boa qualidade utilizados no seu fabrico.
          </p>
          <p>
          Muitos destes pneus são fabricados em países asiáticos, o que permite que sejam vendidos a preços muito mais baixos do que os modelos de outras marcas. Graças à seleção que fazemos na Rodi Motor, pode estar certo de que está a adquirir um produto que foi verificado por profissionais especializados no setor dos pneus. Todos os nossos pneus de gama económica passaram nos testes de qualidade e eficiência exigidos pela União Europeia.
          </p>
        </section>
        <section className={styles.section}>
          <p>
          Algumas das nossas marcas económicas mais vendidas são a Mabor, Taurus, Tigar, e Royal Black.
          </p>
        </section>
      </article>
    </div>
  )
}

export default LandingEconomy

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}

const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-economy'),
        temporada: { selectable: false, value: 'verano' },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'verano',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer showMarcas={false} origin={route('landing-economy')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)
