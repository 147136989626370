import Neumatico from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../assets/neumatico-selector.png'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import React, { useMemo, useState } from 'react'
import { setOrigen } from '../../../context/actions/selectorActions'
import { OrderedMedidas } from '../../../context/reducers/selectorReducer'
import { t } from '../../../i18n'
import Button from '../../buttons/Button'
import CheckBoxYellow from '../../checkboxYellow/CheckBoxYellow'
import DropDown from '../../dropDown/DropDown'
import withMarcas, { InjectedMarcas, Marca } from '../../withMarcas'
import ModalInfoMedidas from '../Common/ModalInfoMedidas/ModalInfoMedidas'
import SelectorDropDown from '../Desktop/Components/SelectorDropDown'
import Step, { findNext, SelectorProps } from '../SelectorSteps'
import styles from './select.module.scss'

interface DefaultValueRuedaProps {
  medida: string
  value: any
  pristine: boolean
  isNext: boolean
  postfix?: string
  prefix?: string
}
const DefaultValueRueda = ({
  medida,
  value,
  pristine,
  isNext,
  postfix = '',
  prefix = '',
}: DefaultValueRuedaProps) => {
  if (value === null) {
    return null
  }

  const classes = [styles[medida + 'Span']]
  if (pristine) {
    classes.push(styles.default)
  }

  return (
    <span className={classes.join(' ')}>
      {prefix}
      <span className={pristine && isNext ? styles.parpadeo : undefined}>
        {value}
      </span>
      {postfix}
    </span>
  )
}

const temporadaOptions =
  process.env.GATSBY_WEB === 'rodi'
    ? [
        { value: 'verano', label: t('SelectorForm.verano') },
        { value: 'invierno', label: t('SelectorForm.invierno') },
        { value: 'allseason', label: t('SelectorForm.allseason') },
      ]
    : [
        { value: 'verano', label: t('SelectorForm.verano') },
        { value: 'allseason', label: t('SelectorForm.allseason') },
      ]

interface Props extends SelectorProps, InjectedMarcas {
  fromModal: boolean
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void
}
const SelectorForm = ({
  skipInfo,
  dispatch,
  selectorState,
  handleChange,
  handleChangeEstacion,
  handleChangeRunflat,
  isValid,
  showMarcas = false,
  marcas,
  handleSubmit,
  origin,
  showTemporada = process.env.GATSBY_WEB !== 'elpaso',
}: Props) => {
  const [showModalMoreInfo, setShowModalMoreInfo] = useState(false)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!isValid(selectorState)) {
      return
    }

    if (origin) {
      dispatch(setOrigen(origin))
    }

    if (handleSubmit) {
      handleSubmit(event)
      return
    }

    const next = findNext(Step.INITIAL, 'desktop', skipInfo)
    navigate(next.routes.desktop)
  }

  const nextMedida = useMemo(() => {
    if (selectorState.pristine) {
      return 'ancho'
    }
    for (let i = 0; i < OrderedMedidas.length; i++) {
      const medida = OrderedMedidas[i]
      if (selectorState[medida].value === null) {
        return medida
      }
    }
    return null
  }, [selectorState])

  const opcionTodas: Marca = {
    coche: true,
    moto: false,
    orden: 0,
    id: 0,
    label: t('menuHeader.todas'),
    value: 0,
    clasificacion: 'TU_SELECCION',
    logo: '',
  }

  return (
    <form onSubmit={onSubmit}>
      <div className={styles.selector_cont}>
        {showMarcas && (
          <div className={styles.selector}>
            <DropDown
              size="big"
              name="marca"
              value={selectorState.marca}
              options={[opcionTodas, ...marcas]}
              onChange={handleChange}
              labelId="selector-desktop.marca"
              labelAbove={true}
            />
          </div>
        )}

        <div className={styles.selector}>
          <SelectorDropDown
            required
            sorted
            size="big"
            name="ancho"
            onChange={handleChange}
            field={selectorState.ancho}
            labelId="selector-desktop.ancho"
            labelAbove={true}
          />
        </div>
        <div className={styles.selector}>
          <SelectorDropDown
            required
            size="big"
            name="serie"
            field={selectorState.serie}
            onChange={handleChange}
            labelId="selector-desktop.serie"
            labelAbove={true}
          />
        </div>
        <div className={styles.selector}>
          <SelectorDropDown
            required
            size="big"
            name="llanta"
            field={selectorState.llanta}
            onChange={handleChange}
            labelId="selector-desktop.llanta"
            labelAbove={true}
          />
        </div>
        <div className={styles.selector}>
          <SelectorDropDown
            required
            size="big"
            name="carga"
            field={selectorState.carga}
            onChange={handleChange}
            labelId="selector-desktop.indice-carga"
            labelAbove={true}
          />
        </div>
        <div className={styles.selector}>
          <SelectorDropDown
            required
            size="big"
            name="velocidad"
            field={selectorState.velocidad}
            onChange={handleChange}
            labelId="selector-desktop.indice-velocidad"
            labelAbove={true}
          />
        </div>
      </div>

      <div className={styles.selector_cont_long}>
        <div className={styles.selector_cont_check}>
          {showTemporada && process.env.GATSBY_WEB !== 'elpaso' && (
            <div className={styles.checkbox_cont}>
              <DropDown
                options={temporadaOptions}
                defaultValue={selectorState.temporada.value || 'verano'}
                onChange={(e) => handleChangeEstacion(e.target.value)}
                size="big"
                labelAbove={true}
                labelId="SelectorForm.estacion"
              />
            </div>
          )}
          <div className={styles.checkbox_cont_2}>
            <div>{t('SelectorForm.opciones')}</div>
            <div className={styles.options_cont}>
              <div className={styles.option}>
                <CheckBoxYellow
                  name="runflat"
                  onChange={handleChangeRunflat}
                  disableCheckBox={!selectorState.runflat.selectable}
                  checked={!!selectorState.runflat.value}
                  size="small"
                  label1={
                    <div
                      className={
                        !selectorState.runflat.selectable
                          ? `${styles.label} ${styles.label_disabled}`
                          : styles.label
                      }>
                      {t('SelectorForm.runflat')}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dudas}>
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className={styles.icon_question}
          />
          <span
            className={styles.dudas_text}
            onClick={() => setShowModalMoreInfo(true)}>
            {t('SelectorForm.dudas')}
          </span>
        </div>
        <div className={styles.media_cont}>
          <div className={styles.image_cont}>
            <img
              alt="Neumatico selector"
              src={Neumatico}
              width={450}
              height={133}
            />
            <DefaultValueRueda
              medida="ancho"
              value={selectorState.ancho.value}
              pristine={selectorState.pristine}
              isNext={nextMedida === 'ancho'}
              postfix=" /"
            />
            <DefaultValueRueda
              medida="serie"
              value={selectorState.serie.value}
              pristine={selectorState.pristine}
              isNext={nextMedida === 'serie'}
            />
            <DefaultValueRueda
              medida="llanta"
              value={selectorState.llanta.value}
              pristine={selectorState.pristine}
              isNext={nextMedida === 'llanta'}
              prefix="R"
            />
            <DefaultValueRueda
              medida="carga"
              value={selectorState.carga.value}
              pristine={selectorState.pristine}
              isNext={nextMedida === 'carga'}
            />
            <DefaultValueRueda
              medida="velocidad"
              value={selectorState.velocidad.value}
              pristine={selectorState.pristine}
              isNext={nextMedida === 'velocidad'}
            />
          </div>

          <div className={styles.button_cont}>
            <Button
              disabled={!isValid(selectorState)}
              buttonClass="rodi_full_width"
              type="submit">
              {t('selector-desktop.buscar')}
            </Button>
          </div>

          <ModalInfoMedidas
            showModalMoreInfo={showModalMoreInfo}
            setShowModalMoreInfo={setShowModalMoreInfo}
          />
        </div>
      </div>
    </form>
  )
}

export default withMarcas(SelectorForm)
